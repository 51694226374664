<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <div class="note">(*) Nội dung không được bỏ trống</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import Config from '../../../config/index'
import DataServices from '@/core/DataServices'
import formEdit from './add'
export default {
  components: {
    formEdit
  },
  data() {
    return {
      title: "Tạo người dùng",
      mode: 'add',
      models: {},
      componentKey: 0
    }
  },
  async mounted () {
    this.mode = this.$route.name === 'editManager' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa người dùng' : this.title;
    if (this.$route.params.id !== undefined) {
      await this.getData(this.$route.params.id);
    }
  },
  methods: {
    backToList() {
      this.$router.push({ path: '/admin/list-manager' });
    },
    async saveData(params) {
      if (params !== undefined && Object.entries(params).length > 0) {
        var objects = {
          'fullname': params.name,
          'email': params.email,
          'is_admin': true
        }
        if (this.mode === 'add') {
          await this.$apollo.mutate({
            mutation: DataServices.insertData('users'),
            variables: {
              'objects': objects
            }
          }).then(async (res) => {
            // console.log('========> SUCCESS INSERT ADMIN: ', res.data)
            if (res.data[Object.keys(res.data)].affected_rows) {
              var returning = res.data[Object.keys(res.data)].returning[0];
              // await this.uploadImages(returning.id);
              // TRIGGER HASURA TO CALL FIREBASE FUNCTION CREATE USER
              let query = `mutation MyMutation {
                createUser(email: "${params.email}", fullname: "${params.name}", password: "${params.password}") {
                  uid
                }
              }`;
              await this.$apollo.mutate({
                mutation: gql(query)
              }).then(async (res) => {
                // console.log('=========> createUser: ', res)
                var firebaseID = res.data.createUser.uid;
                if (firebaseID) {
                  let insertData = {
                    'id': firebaseID,
                    'uid': returning.id,
                    'account': params.email,
                    'type': 'EMAIL',
                    'role': 'admin'
                  }
                  await this.$apollo.mutate({
                    mutation: DataServices.insertData('accounts'),
                    variables: {
                      'objects': insertData
                    }
                  }).then(async () => {
                    // console.log('TAO THANH CONG ACCOUNT', firebaseID)
                    let update_variables = {
                      'data_update': {
                        'role': 'admin'
                      },
                      'where_key': {
                        'id': {'_eq': firebaseID}
                      }
                    };
                    await this.$apollo.mutate({
                      mutation: DataServices.updateData('accounts'),
                      variables: update_variables
                    }).then(() => {
                      this.backToList();
                    }).catch((error) => {
                      console.error('ERROR: ', error)
                    })
                  }).catch((error) => {
                    console.error('ERROR: ', error)
                  })
                }
              }).catch((error) => {
                console.error('ERROR: ', error)
              });
            }
          }).catch((error) => {
            console.error('ERROR: ', error)
          });
        } else {
          let variables = {
            'data_update': {
              'fullname': params.name
            },
            'where_key': {
              'id': {'_eq': this.models.id}
            }
          };
          this.$apollo.mutate({
            mutation: DataServices.updateData('users'),
            variables: variables
          }).then(async (res) => {
            if (res.data[Object.keys(res.data)].affected_rows) {
              // TRIGGER HASURA TO CALL FIREBASE FUNCTION CHANGE PASSWORD
              let query = `mutation MyMutation {
                changePassword(password: "${params.password}", uid: "${this.models.users_account[0].id}") {
                  uid
                }
              }`;
              await this.$apollo.mutate({
                mutation: gql(query)
              }).then(() => {
                console.log('CHANGE PASSWORD DONE');
                this.backToList();
              }).catch((error) => {
                console.error('ERROR: ', error)
              })
            }
          }).catch((error) => {
            console.error('ERROR: ', error)
          });
        }
      }
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('users', {"fields" : "id fullname email users_account { id }"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    }
  },
  apollo: {
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 24px;
    .p-field{
      .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
      .p-col-width{width:500px !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;}
      }
      .box-images{ margin-top: 10px; position: relative;
        .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
          &.p-col-5{width: 18%;;}
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
          .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }
  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 22px; border-top: 1px solid #B4B4B4; padding: 0; padding-top: 30px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>