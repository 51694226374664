<template>
  <div class="p-formgrid">
      <Panel>
        <template #header>
            Thông tin chung
        </template>
        <div class="p-card-field">
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Tên hiển thị (*)</label>
            <div class="p-col">
              <InputText v-model="name"  id="name" type="text" class="p-col-width" placeholder="Nhập tên hiển thị" />
              <small
                v-if="validationErrors && validationErrors['name']"
                class="p-invalid"
              >{{validationErrors['name']}}</small>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Email (*)</label>
            <div class="p-col">
              <InputText v-model="email" :disabled="checkFormMode()" id="email" type="text" class="p-col-width" placeholder="Nhập địa chỉ email"/>
              <small 
                v-if="validationErrors && validationErrors['email'] && formMode == 'add'"
                class="p-invalid"
              >{{validationErrors['email']}}</small>
              <small
                v-if="key_exists && formMode == 'add'"
                class="p-invalid"
              > Tài khoản đã tồn tại</small>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed txt-right">Mật khẩu (*)</label>
            <div class="p-col">
              <InputText v-model="password"  id="password" type="password" class="p-col-width"/>
              <small
                v-if="validationErrors && validationErrors['password']"
                class="p-invalid"
              >{{validationErrors['password']}}</small>
            </div>
          </div>
        </div>
    </Panel>
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          label="Quay lại"
          @click="backToList()"
          class="p-button-warning callback-btn"
        />
        <Button
          v-if="mode == 'edit'"
          :label="'Lưu thay đổi'"
          @click="saveData()"
          class="p-button-success main-style-button"
        />
        <Button
          v-else
          :label="'Tạo tài khoản'"
          @click="saveData()"
          class="p-button-success main-style-button"
        />
      </template>
    </Toolbar>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
export default {
  props: {
    mode: String,
    model: Object
  },
  data() {
    return {
      password: '',
      name: '',
      email: '',
      validationErrors: {},
      formMode: this.mode,
      key_exists: false,
      count: 0
    }
  },
  mounted() {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.name = this.model.fullname;
      this.email = this.model.email;
    }
  },
  methods: {
    checkFormMode() {
      var result = false;
      if (this.formMode == 'edit') {
        result = true;
      }
      return result;
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};
      if (this.name === '') this.validationErrors['name'] = 'Vui lòng nhập tên hiển thị';
      if (this.email === '') this.validationErrors['email'] = 'Vui lòng nhập địa chỉ email';
      if (this.password === '') this.validationErrors['password'] = 'Vui lòng nhập mật khẩu';
      else if (this.password.length < 6) this.validationErrors['password'] = 'Mật khẩu phải >= 6 ký tự';
      if (this.formMode == 'add') {
        this.key_exists = await this.checkEmailExist();
      }
    },
    async checkEmailExist() {
      if (this.email !== undefined && this.email !== "") {
        var where = {
          account: {'_eq': this.email},
          type: {'_eq': 'EMAIL'},
          deleted: {'_eq': false}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('accounts', {"fields" : "id, account, type"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        return data && data[Object.keys(data)].length > 0
      }
    },
    async saveData() {
      await this.validateData();
      if (!this.key_exists && Object.entries(this.validationErrors).length === 0) {
        var params = {
          name: this.name,
          email: this.email,
          password: this.password
        }
        this.$emit('save', params);
      }
    }
  },
  apollo: {
  }
}
</script>

<style lang="scss">
</style>
